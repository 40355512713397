<template>
  <v-container class="sidebar--navigation">
 <v-row justify-center>
    <v-col cols="12">
      <v-card elevation="2">
        <v-row no-gutters>
          <v-col cols="4"
              :style="{ paddingTop: '100px' }"
          >
            <v-img
              v-if="product.fetched_imagesdata != ''"
              :src="getProductImage(product.fetched_imagesdata)"
              height="200"
              contain
            ></v-img>
             <v-img
                v-else
                :src="require('@/assets/images/misc/no-image.png')"
                height="200"
                contain
            ></v-img>
          </v-col>
          <v-col cols="8">
            <v-card-title class="text-h5 grey lighten-2 d-flex mb-2 mt-5">
              <v-img
                max-height="35"
                max-width="35"
                :src="require('@/assets/images/logos/etsy.png')"
              ></v-img>
              &nbsp;Product Details
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text v-if="product.id !== undefined">
              <v-row>
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Listing ID: {{ listing.listing_id }}</p>
                  <p class="font-weight-bold">Title: {{ product.title }}</p>
                  <p class="font-weight-bold">SKU: {{ product.sku }}</p>
                  <p class="font-weight-bold">Created On: {{ product.created_on }}</p> 
                  <template >
                     <p class="font-weight-bold mt-10">Dimensions:</p>
                      <v-row>
                        <v-col cols="12">
                          <template>
                            <p class="font-weight-bold">
                              Item Length: {{listing.item_length != null ? `${listing.item_length} ${listing.item_dimensions_unit}`: 'Not Available'}}
                            </p>
                              <p class="font-weight-bold">
                              Item Breadth: {{listing.item_breadth != null ? `${listing.item_breadth} ${listing.item_dimensions_unit}`: 'Not Available'}}
                            </p>
                              <p class="font-weight-bold">
                              Item Height: {{listing.item_height != null ? `${listing.item_height} ${listing.item_dimensions_unit}`: 'Not Available'}}
                            </p>
                          </template>
                        </v-col>
                      </v-row>
                  </template>
                </v-col>
                
                <v-col cols="12" md="6">
                  <p class="font-weight-bold">Price: {{ listing.currency }} {{ listing.price/listing.divisor }}</p>
                  <p class="font-weight-bold">Quantity: {{ listing.quantity }}</p>
                  <p class="font-weight-bold">Who made?: {{ listing.who_made }}</p>
                  <p class="font-weight-bold">When made?: {{ listing.when_made }}</p>
                  <p class="font-weight-bold">URL: <a target="_blank" :href="listing.url">{{ listing.url }}</a></p>
                  <p class="font-weight-bold">Description: {{ JSON.parse(product.upload_result).description }}</p>
              
                </v-col>
              </v-row>

            </v-card-text>
            <v-card-text v-else>
              <div style="padding: 105px 0px" class="text-center">
                <h3>Something went Wrong!!</h3>
              </div>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
    </br>


    <v-card class="p-1">
      <v-card-title>
         Product Variations
      </v-card-title>

      <v-data-table :headers="headers" :items="variations" disable-pagination hide-default-footer>
        <template v-slot:body="{ items }">
          <tbody v-if="!loading_data">
            <tr v-if="!items.length">
              <td colspan="6" class="text-center" style="padding: 20px 0px">
                <img :src="require('@/assets/images/misc/no-data.png')" alt="" width="250px" />
                <p style="font-size: 25px">No Data Found!!</p>
              </td>
            </tr>
            <tr v-else v-for="item in items" :key="item.id">
              <td>
               #{{ JSON.parse(item.offerings)[0].offering_id}}
              </td>
              <td style="max-width: 300px; font-size: 12px">
              <strong>{{ item.title }}</strong>
              </td>
              <td>{{ item.sku }}</td>
              <td>
                  {{ item.quantity}}
              </td>
                <td>
                  <span v-if="JSON.parse(item.property_values).length !== 0">
                  {{ JSON.parse(item.property_values)[0].property_name }}: {{ JSON.parse(item.property_values)[0].values.join(', ') }}
                  </span>
                  <span v-else>
                    No Property
                  </span>
              </td>
              <td>
                {{ JSON.parse(item.offerings)[0].price.currency_code }}
                {{ JSON.parse(item.offerings)[0].price.amount/JSON.parse(item.offerings)[0].price.divisor }}
              </td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr>
              <td colspan="7" class="text-center" style="padding: 80px 0px">
                <img :src="require('@/assets/images/misc/loading.gif')" alt="" width="100px" />
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>

  </v-container>
</template>



<script>
import store from '@/store'
import { mdiChevronDown, mdiClose, mdiCheckCircle,
mdiCancel } from '@mdi/js'
export default {
  data() {
    return {
      etsyProductId: null,
      product: {},
      variations: [],
      loading_data: false,
      shop: null,
      listing: {
        listing_id: null,
        price: null,
        divisor: 1,
        listing_type: '',
        item_length: null,
        item_breadth: null,
        item_height: null,
        item_dimensions_unit: null,
        who_made: '',
        when_made: '',
        quantity: null,
        url: ''
     },
        headers: [
        {
          text: 'Listing ID',
          value: 'listing_id',
        },
        {
          text: 'Title',
          value: 'title',
        },
        {
          text: 'SKU',
          value: 'sku',
        },
        {
          text: 'Quantity',
        },
        {
          text: 'Property',
        },
        {
          text: 'Price',
        },
      ],
      icons: {
        mdiChevronDown,
        mdiClose,
        mdiCheckCircle,
        mdiCancel
      },
      variation_headers: [
        {
          text: 'Attributes',
        },
        { text: 'SKU' },
        { text: 'Price' },
        { text: 'Stock' },
      ],

    }
  },

  async mounted() {
    this.etsyProductId = this.$route.params.etsy_product_id;
    this.shop = store.getters.loggedInShop
    this.fetchEtsyProduct()
  },

  methods: {

     async fetchEtsyProduct() {
      try {
        this.loading_data = true
        let response = await axios.get(`admin/shops/${this.shop.id}/get-etsy-product/${this.etsyProductId}`, {
        })
        if (response.data.success) {
          this.product = response.data.product
          this.variations = response.data.variations == null ? [] : response.data.variations
          this.setListingData(response.data.product.upload_result)
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading_data = false
      }
    },
    
    getProductImage(attributes) {
      try {
          const parsedAttributes = JSON.parse(attributes);
          if (Array.isArray(parsedAttributes) && parsedAttributes.length > 0) {
            return parsedAttributes[0].url_fullxfull;
          }
        } catch (error) {
          console.error("Error parsing attributes:", error);
        }
        return require('@/assets/images/misc/no-image.png'); 
    },

    setListingData(data) {
      data = JSON.parse(data)
      this.listing.price = data.price.amount;
      this.listing.divisor = data.price.divisor;
      this.listing.currency = data.price.currency_code;
      this.listing.listing_type = data.listing_type;
      this.listing.item_length = data.item_length;
      this.listing.item_breadth = data.item_width;
      this.listing.item_height = data.item_height;
      this.listing.item_dimensions_unit = data.item_dimensions_unit;
      this.listing.who_made = data.who_made;
      this.listing.when_made = data.when_made;
      this.listing.quantity = data.quantity;
      this.listing.url = data.url;
      this.listing.listing_id = data.listing_id;
    }

  },
 
}
</script>